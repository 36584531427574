import React from 'react';
import CardFrontVectorImg from '../../img/Card-front-vector.png';
import CardShadow from '../../img/Card-Shadow.svg';
const CardBenefit = (props) => {
    const priceDetail = [{
        heading: "Annual fee:",
        detail: "$59"
    },
    {
        heading: "Security deposit:",
        detail: "None"
    },
    {
        heading: "Variable APR:",
        detail: "28.99%"
    }]
    return (
        <>
            <div className="row mt-3">
                <div className="cardbenefitWrapper text-start">
                    {
                        priceDetail.map((x,index) =>
                            <div className="priceDetailBox" key={index}>
                                <div className="priceDetailHeader">{x.heading}</div>
                                <div className="priceDetailAmount"><b>{x.detail}</b></div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default CardBenefit;